<template>
    <v-form ref="filterform">
    <v-list dense class="px-4 pb-0">
      <template
        v-for="({
          heading, component, props, value, listeners
        }, i) in filtersList"
      >
        <v-subheader v-if="heading" :key="`heading-${i}`">{{ heading }}</v-subheader>

        <v-skeleton-loader
          ref="skeleton"
          v-else :key="`element-${i}`"
          :loading="!loaded"
          :type="'list-item-avatar'"
          :tile="true"
          :class="{ 'mb-1': !loaded }"
          class="mx-auto"
        >

        <v-list-item class="px-0">
          <v-list-item-content>

            <component
              :is="component || 'custom-select'"
              :value="value"
              v-on="listeners"
              v-bind="props"
            ></component>

          </v-list-item-content>
        </v-list-item>

        </v-skeleton-loader>

      </template>
    </v-list>
    </v-form>
</template>

<script>
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import { Providers, Enums } from '@flightscope/baseball-stats';
import { Session  } from '@/models/orm/Hierarchy';

import ScoringCountsFilter from '@/components/filters/ScoringCountsFilter.vue';
import PitchResultDropdown from '@/components/filters/PitchResultDropdown.vue';
import PitchTypeDropdown from '@/components/filters/PitchTypeDropdown.vue';
import PlayOutcomeDropdown from '@/components/filters/PlayOutcomeDropdown.vue';
import CustomSelect from '@/components/ui/selection-controls/CustomSelect.vue';
import ZoneDropdown from '@/components/ui/dropdowns/ZoneDropdown.vue';

import UniqueValueForFilters from '@/components/mixins/UniqueValueForFilters';
import ResultFilter from '@/filters/ResultFilter';


import { SessionType } from '@/store/actions/api';
import { defaultSessionPlayerWithID } from '@/utils/mappers';
import FilterStores from '@/enums/StoreKeys';
import { EPitchParameterKeys, EHitParameterKeys } from '@/filters/enums';

const headings = {
  SCORING: 'Scoring',
  BATTING: 'Batting',
  PITCHING: 'Pitching',
  FILTERS: 'Filters',
};

const selectsCollection = [
  {
    name: ResultFilter.PitcherHandedness.key,
    label: ResultFilter.PitcherHandedness.name,
    def: '',
  },
  {
    name: ResultFilter.Pitcher.key,
    label: ResultFilter.Pitcher.name,
    group: FilterStores.GLOBAL,
    def: '',
    forceFirst: true,
    comp: 'v-autocomplete'
  },
  {
    name: ResultFilter.Pitchers.key,
    label: ResultFilter.Pitchers.name,
    multiple: true,
    showAll: true,
    def: [],
    comp: 'v-autocomplete'
  },
  {
    name: ResultFilter.PlayOutcome.key,
    label: ResultFilter.PlayOutcome.name,
    component: PlayOutcomeDropdown,
    multiple: true,
    def: [],
    sessionFeatures: 'S',
    hasExtendedTagging: false,
    valueKey: 'selectedItems',
  },
  {
    name:  ResultFilter.PitchResult.key,
    label: ResultFilter.PitchResult.name,
    component: PitchResultDropdown,
    multiple: true,
    def: [],
    sessionFeatures: 'T',
    valueKey: 'selectedItems',
  },
  {
    name: ResultFilter.PitchType.key,
    label: ResultFilter.PitchType.name,
    component: PitchTypeDropdown,
    multiple: true,
    def: [],
    sessionFeatures: 'T',
    valueKey: 'selectedItems',
  },
  {
    name: ResultFilter.BatterHandedness.key,
    label: ResultFilter.BatterHandedness.name,
    def: '',
  },
  {
    name: ResultFilter.Batter.key,
    label: ResultFilter.Batter.name,
    group: FilterStores.GLOBAL,
    def: '',
    forceFirst: true,
    comp: 'v-autocomplete'
  },
  {
    name: ResultFilter.Batters.key,
    label: ResultFilter.Batters.name,
    multiple: true,
    showAll: true,
    def: [],
    comp: 'v-autocomplete'
  },
  {
    name: ResultFilter.PitchParameter.filterKey,
    label: ResultFilter.PitchParameter.name,
    forceFirst: true,
    def: '',
  },
  {
    name: ResultFilter.HitParameter.filterKey,
    label: ResultFilter.HitParameter.name,
    forceFirst: true,
    def: '',
  },
  {
    name: ResultFilter.Zones.key,
    label: ResultFilter.Zones.name,
    component: 'zone-dropdown',
    multiple: true,
    def: [],
    sessionFeatures: 'R',
    valueKey: 'selectedItems',
  },
];

const baseballSelects = [
  {
    name: ResultFilter.PitchSet.key,
    label: ResultFilter.PitchSet.name,
    def: '',
    sessionFeatures: 'T',
  },
];

const scoringSelects = [
  {
    name: ResultFilter.ScoringOuts.key,
    label: ResultFilter.ScoringOuts.name,
    multiple: true,
    def: [],
  },
  {
    name: ResultFilter.ScoringCounts.key,
    label: ResultFilter.ScoringCounts.name,
    component: 'scoring-counts-filter',
    multiple: true,
    def: undefined,
  },
  {
    name: ResultFilter.ScoringRunnersOn.key,
    label: ResultFilter.ScoringRunnersOn.name,
    def: '',
  }
];

const scoringFiltersCollection = [
  { heading: headings.SCORING },
  { select: ResultFilter.ScoringOuts.key },
  { select: ResultFilter.ScoringCounts.key },
  { select: ResultFilter.ScoringRunnersOn.key }
];

const filtersCollection = {
  [FilterStores.PITCHING_SUMMARY]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.PitcherHandedness.key },
    { select: ResultFilter.Pitchers.key },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchType.key },
    { heading: headings.BATTING },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key, excludeInType: SessionType.PITCHING },
  ],
  [FilterStores.PITCHING_POST_GAME]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Pitchers.key },
  ],
  [FilterStores.HITTING_POST_GAME]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Batters.key },
  ],
  [FilterStores.RELEASE_POINT]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Pitcher.key, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchType.key },
    { select: ResultFilter.PitchSet.key, excludeInSport: Enums.SportType.Softball.key },
    { heading: headings.BATTING },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key },
  ],
  [FilterStores.RELEASE_EXTENSION]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Pitcher.key, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchType.key },
    { select: ResultFilter.PitchSet.key, excludeInSport: Enums.SportType.Softball.key },
    { heading: headings.BATTING },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key },
  ],
  [FilterStores.PITCH_MOVEMENT]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Pitcher.key, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchType.key },
    { select: ResultFilter.PitchSet.key, excludeInSport: Enums.SportType.Softball.key },
    { heading: headings.BATTING },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key },
  ],
  [FilterStores.LOCATION_CHART]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Pitcher.key, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchType.key },
    { select: ResultFilter.PitchSet.key, excludeInSport: Enums.SportType.Softball.key },
    { select: ResultFilter.Zones.key },
    { heading: headings.BATTING },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key },
  ],
  [FilterStores.PITCH_TRACKING]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Pitcher.key, required: true },
    { select: ResultFilter.PitchParameter.filterKey, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchType.key },
    { select: ResultFilter.PitchSet.key, excludeInSport: Enums.SportType.Softball.key },
    { heading: headings.BATTING },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key },
  ],
  [FilterStores.HITTING_SUMMARY]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.BatterHandedness.key },
    { select: ResultFilter.Batters.key },
    { heading: headings.PITCHING },
    { select: ResultFilter.PitcherHandedness.key },
    // for BattingSession - no pitch /tagging filter only handedness once added in synchroniser
    { select: ResultFilter.Pitchers.key, excludeInType: SessionType.HITTING },
    { select: ResultFilter.PitchResult.key, excludeInType: SessionType.HITTING },
    { select: ResultFilter.PitchType.key, excludeInType: SessionType.HITTING },
  ],
  [FilterStores.STRIKEZONE_CHART]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Batter.key, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.Zones.key },
    { heading: headings.PITCHING },
    { select: ResultFilter.PitchType.key, excludeInType: SessionType.HITTING },
    { select: ResultFilter.PitcherHandedness.key },
    { select: ResultFilter.Pitchers.key, excludeInType: SessionType.HITTING },
  ],
  [FilterStores.HIT_SPRAY]: [
    { heading: headings.FILTERS },
    // TODO: maybe all batters
    { select: ResultFilter.Batter.key, required: true },
    { select: ResultFilter.PitchResult.key }, // todo - add zones definition
    { select: ResultFilter.Zones.key },
    { heading: headings.PITCHING },
    { select: ResultFilter.PitchType.key, excludeInType: SessionType.HITTING },
    { select: ResultFilter.PitcherHandedness.key },
    { select: ResultFilter.Pitchers.key, excludeInType: SessionType.HITTING },
  ],
  [FilterStores.HIT_TRACKING]: [
    { heading: headings.FILTERS },
    { select: ResultFilter.Batter.key, required: true },
    { select: ResultFilter.HitParameter.filterKey, required: true },
    { select: ResultFilter.PitchResult.key },
    { select: ResultFilter.PitchSet.key, excludeInSport: Enums.SportType.Softball.key }, // todo - add zone here
    { heading: headings.PITCHING },
    { select: ResultFilter.PitchType.key, excludeInType: SessionType.HITTING },
    { select: ResultFilter.PitcherHandedness.key },
    { select: ResultFilter.Pitchers.key, excludeInType: SessionType.HITTING },
  ],
  [FilterStores.CONTACT_POINT]: [
    { heading: headings.FILTERS },
    // TODO: maybe all batters
    { select: ResultFilter.Batter.key, required: true },
    { select: ResultFilter.PitchResult.key }, // todo - add zones definition
    { heading: headings.PITCHING },
    { select: ResultFilter.PitchType.key, excludeInType: SessionType.HITTING },
    { select: ResultFilter.PitcherHandedness.key },
    { select: ResultFilter.Pitchers.key, excludeInType: SessionType.HITTING },
  ],
};

const i18n = {
  errors: {
    should_not_invoke: 'This method should not be invoked before data is available',
  },
};

function parameterReports(keys, enumObj) {
  let items = [];
  for (let i = 0; i < keys.length; i++) {
    const paramKey = keys[i];
    let enumDef = Providers.EnumValueProvider.getValue(paramKey, enumObj);
    if (enumDef) {
      items.push({
        value: paramKey, text: enumDef.name,
      });
    }
  }
  return items;
}

export default {
  name: 'VerticalFilters',
  mixins: [UniqueValueForFilters],

  inject: ['repo'],

  props: {
    session: {
      type: Session,
    },

    filters_key: {
      type: String,
      required: true,
    },

    refreshSession: {
      type: Function,
      default: () => {},
    },

    SessionRepo: {
      default() {
        return this.repo.get('sessions');
      },
    },
  },

  components: {
    CustomSelect,
    ScoringCountsFilter,
    ZoneDropdown,
  },

  data() {
    return {
      menuProps: {
        offsetY: true,
        maxHeight: 'auto'
      },

      filtersList: [],
    };
  },

  computed: {
    loaded() {
      return this.session?.hasPlayersLoaded;
    },
    dataFilters() {
      return this.session?.filters;
    },

    collection() {
      let collection = filtersCollection[this.filters_key];

      if ([FilterStores.PITCHING_POST_GAME, FilterStores.HITTING_POST_GAME].includes(this.filters_key)) {
        return collection;
      }

      if (this.session && !this.session.hasExtendedTagging && this.session.mightHaveScoring) {
        collection = [].concat([], collection, scoringFiltersCollection);
      }
      return collection;
    },

    filtersView() {
      if (!this.loaded) {
        return [];
      }

      const mappedCollection = this.collection.map(this.mapCollectionItem).filter(i => i);

      return mappedCollection;
    },

    filtersGuard() {
      if (this.filtersView.length && this.filtersView.some(f => f?.props?.required && !f.value)) {
        return false;
      }
      return true;
    },

    requiredFilters() {
      return this.filtersView.filter(item => item?.props?.required).map(item => {
        return {
          key: item.props.name,
          group: item?.group || this.filters_key,
        };
      });
    },

    selectsList() {
      let collection = selectsCollection;

      if (process.env.VUE_APP_SPORT_TYPE === Enums.SportType.Baseball.key) {
        collection = [].concat(collection, baseballSelects);
      }

      if (this.session && this.session.mightHaveScoring) {
        collection = [].concat(collection, scoringSelects);
      }
      return collection;
    },

    watchedDataSet() {
      return {
        isSet: this.loaded,
        filters: this.dataFilters,
      };
    },
  },

  methods: {
    dataGuard() {
      if (!this.session || !this.session.hasPlayersLoaded) {
        throw new Error(i18n.errors.should_not_invoke);
      }
    },

    updateFilters(val, group, key) {
      this.dataFilters[group][key] = val;
      Session.update({
        id: this.session.id,
        filters: this.dataFilters,
      });
      this.refreshSession();
    },

    getOptions(selectionType, filtered) {
      if (!this.loaded) { return []; }
      this.dataGuard();

      switch (selectionType) {
        case ResultFilter.Batters.key:
        case ResultFilter.Batter.key:
          return this.SessionRepo.mapPlayersToOptions(this.session, this.session.batters);

        case ResultFilter.Pitchers.key:
        case ResultFilter.Pitcher.key:
          return this.SessionRepo.mapPlayersToOptions(this.session, this.session.pitchers);

        case ResultFilter.PitcherHandedness.key:
        case ResultFilter.BatterHandedness.key:
        case ResultFilter.PitchSet.key:
        case ResultFilter.ScoringOuts.key:
        case ResultFilter.ScoringRunnersOn.key:
          if (filtered) {
            return this.getFilteredOptions(selectionType);
          }
          return this.getNonFilteredOptions(selectionType);

        case ResultFilter.PitchParameter.filterKey: return parameterReports(EPitchParameterKeys, Enums.PitchData);

        case ResultFilter.HitParameter.filterKey: return parameterReports(EHitParameterKeys, Enums.HitData);

        // TODO: maybe set some data here
        case ResultFilter.PlayOutcome.key:
        case ResultFilter.ScoringCounts.key:
          // will be handled by child component
          return undefined;

        default:
          return [];
      }
    },

    mapCollectionItem(item) {
      const { select, excludeInType, excludeInSport, required } = item;

      if (select) {
        if (
          (excludeInType instanceof Array && excludeInType.length && excludeInType.includes(this.type))
          || (excludeInSport && excludeInSport === process.env.VUE_APP_SPORT_TYPE)
          || (excludeInType && excludeInType === this.type)
        ) {
          return null;
        }

        let selectItem;

        if (select === ResultFilter.PitchResult.key) {
          if (this.session.v2 || this.session.hasExtendedTagging) {
            selectItem = find(this.selectsList, { name: ResultFilter.PlayOutcome.key });
            selectItem.hasExtendedTagging = this.session.hasExtendedTagging
          }
        } else {
          selectItem = find(this.selectsList, { name: select });
        }

        if (selectItem) {
          const {
            name, component, group, def, sessionFeatures, valueKey, ...props
          } = selectItem;

          if (sessionFeatures && !this.session.features[sessionFeatures]) {
            return null;
          }

          let endGroup = group || this.filters_key;

          let value = get(this.session.filters, [endGroup, name], def);

          let items = this.getOptions(name);

          if (required && !value && items.length) {
            let first = items.find(i => i.value);
            if (first?.value) {
              value = first?.value;
              this.updateFilters(value, endGroup, name);
            }
          }

          let output = {
            component,
            group: endGroup,
            props: {
              ...props,
              required,
              name,
              items: items,
            },
            listeners: {
              input: ($event) => {
                this.updateFilters($event, endGroup, name);
              },
            },
          };

          if (valueKey) {
            output.props[valueKey] = value;
          } else {
            output.value = value;
          }

          if (name === ResultFilter.ScoringCounts.key) {
            output.value = undefined;
            output.listeners = {
              ...output.listeners,
              'update:balls': ($event) => this.updateFilters($event, endGroup, ResultFilter.ScoringBalls.key),
              'update:strikes': ($event) => this.updateFilters($event, endGroup, ResultFilter.ScoringStrikes.key),
            };
            output.props = {
              ...output.props,
              balls: get(this.session.filters, [endGroup, ResultFilter.ScoringBalls.key], ''),
              strikes: get(this.session.filters, [endGroup, ResultFilter.ScoringStrikes.key], ''),
            };
          }

          return output;
        }
      }
      return item;
    },

    setItems(select) {
      if (select?.props) {
        select.props.items = this.getOptions(select.props.name, true);
      }
      return select;
    },

    watchFilterSet(val, oldVal) {
      if (!this.loaded || !this.filtersGuard) {
        return;
      }


      this.getPossibleParameters(val.filters);

      this.filtersList = map(this.filtersView, this.setItems);
    },
  },

  watch: {
    watchedDataSet: {
      handler: 'watchFilterSet',
      immediate: true,
    },
  },

  mounted() {
    this.$log.debug(this.$options.name, 'mounted');
  },
};
</script>
